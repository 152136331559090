import { useIntl } from "gatsby-plugin-intl"
import React, { useEffect, useState } from "react"
import axios from "axios"
import ContentContainer from "../../templates/ContentContainer"
import numberWithCommas from "../../utils/numberWithCommas"
import SectionHeading from "../SectionHeading"
import { URLProvider } from "../../utils/URLProvider"

type MetricColumnProps = {
    title: string
    value: string
    currency: string
}

const getCirculatingSupply = async () => {
    const EXPLORER_API_URL = "https://explorer.zensystem.io/insight-api-zen/"
    const data = await axios
        .get(`${EXPLORER_API_URL}/status?q=getTotalSupply`)
        .then((res) => {
            return res.data.supply.toFixed(0)
        })
    return data
}

const getZenMarketInfo = async () => {
    const data = await axios.get(URLProvider.URL_COINGECKO_API).then((res) => {
        return res.data
    })
    return {
        zenPrice: data.market_data.current_price.usd,
        volume: data.market_data.total_volume.usd,
    }
}

const MetricColumn = ({ title, value, currency }: MetricColumnProps) => {
    return (
        <div className="text-center">
            <span className="block text-lg text-horizen-content-grey leading-6">
                {title}
            </span>
            <hr className="my-4" />
            <span className="block font-bold text-[41px] leading-10">
                {value}
            </span>
            <span className="block font-bold leading-5">{currency}</span>
        </div>
    )
}

const withFallbackValue = (param) => {
    if (
        param === null ||
        param === undefined ||
        param === 0 ||
        param === "" ||
        param === "0"
    ) {
        return "-"
    }
    return param
}

const MetricsOverview = () => {
    const [circulatingSupply, setCirculatingSupply] = useState(0)
    const [zenPrice, setZenPrice] = useState("")
    const [volume, setVolume] = useState(0)

    useEffect(() => {
        const updateCirculatingSupply = async () => {
            setCirculatingSupply(await getCirculatingSupply())
        }

        const updateMarketInfo = async () => {
            const marketInfo = await getZenMarketInfo()
            setZenPrice(marketInfo.zenPrice.toFixed(2))
            setVolume(Math.ceil(marketInfo.volume))
        }

        updateCirculatingSupply()
        updateMarketInfo()
    }, [])
    const { formatMessage } = useIntl()
    return (
        <section className="text-white">
            <ContentContainer>
                <div className="text-center">
                    <SectionHeading>
                        {formatMessage({ id: "zen.metrics.sectionHeading" })}
                    </SectionHeading>
                </div>
                <div className="h-[25px] border border-b-0 mt-8 mb-14" />
                <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-7">
                    <MetricColumn
                        title={formatMessage({
                            id: "zen.metrics.zenCurrentPrice",
                        })}
                        value={`$${withFallbackValue(
                            numberWithCommas(zenPrice)
                        )}`}
                        currency="USD"
                    />
                    <MetricColumn
                        title={formatMessage({
                            id: "zen.metrics.volume",
                        })}
                        value={`$${withFallbackValue(
                            numberWithCommas(volume)
                        )}`}
                        currency="USD"
                    />
                    <MetricColumn
                        title={formatMessage({
                            id: "zen.metrics.totalSupply",
                        })}
                        value={numberWithCommas(21000000)}
                        currency="ZEN"
                    />
                    <MetricColumn
                        title={formatMessage({
                            id: "zen.metrics.circulatingSupply",
                        })}
                        value={withFallbackValue(
                            numberWithCommas(circulatingSupply)
                        )}
                        currency="ZEN"
                    />
                </div>
                <div className="text-white text-sm mt-10">
                    {formatMessage({ id: "exchanges.prices.metricsPoweredBy" })}{" "}
                    <a href={URLProvider.URL_COINGECKO} className="text-links">
                        CoinGecko
                    </a>
                </div>
            </ContentContainer>
        </section>
    )
}

export default MetricsOverview
