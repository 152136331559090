import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import ContentContainer from "../../templates/ContentContainer"

export type InfoBoxProps = {
    titleId: string
    descId: string
    hrefId: string
    linkLabelId: string
    hrefId2?: string
    linkLabel2?: string
}

const InfoBox = ({
    titleId,
    descId,
    hrefId,
    linkLabelId,
    hrefId2,
    linkLabel2,
}: InfoBoxProps) => {
    const { formatMessage } = useIntl()
    return (
        <div className="pt-7 pb-9 px-7 border flex flex-col justify-between min-h-[238px]">
            <span className="text-[41px] font-bold">
                {formatMessage({ id: titleId })}
            </span>
            <span className="text-horizen-content-grey block text-lg">
                {formatMessage({ id: descId })}
            </span>
            <div className="flex space-x-12">
                <a
                    className="text-links block"
                    href={formatMessage({ id: hrefId })}
                >
                    [ {formatMessage({ id: linkLabelId })} ]
                </a>
                {hrefId2 && (
                    <a
                        className="text-links block"
                        href={formatMessage({ id: hrefId2 })}
                    >
                        [ {formatMessage({ id: linkLabel2 })} ]
                    </a>
                )}
            </div>
        </div>
    )
}

const InfoBoxes = () => {
    const { formatMessage } = useIntl()

    const renderInfoBoxes = () => {
        const infoBoxes = []
        let index = 0
        while (
            formatMessage({ id: `zen.infoboxes.${index}.title` }) !==
            `zen.infoboxes.${index}.title`
        ) {
            infoBoxes.push(
                <InfoBox
                    key={index}
                    titleId={`zen.infoboxes.${index}.title`}
                    descId={`zen.infoboxes.${index}.desc`}
                    hrefId={`zen.infoboxes.${index}.href`}
                    linkLabelId={`zen.infoboxes.${index}.linkLabel`}
                    hrefId2={
                        formatMessage({
                            id: `zen.infoboxes.${index}.href2`,
                        }) !== `zen.infoboxes.${index}.href2` &&
                        `zen.infoboxes.${index}.href2`
                    }
                    linkLabel2={
                        formatMessage({
                            id: `zen.infoboxes.${index}.linkLabel2`,
                        }) !== `zen.infoboxes.${index}.linkLabel2` &&
                        `zen.infoboxes.${index}.linkLabel2`
                    }
                />
            )
            index += 1
        }

        return infoBoxes.map((infoBox) => infoBox)
    }
    return (
        <section>
            <ContentContainer>
                <div className="text-white gap-[26px] grid lg:grid-cols-2">
                    {renderInfoBoxes()}
                </div>
            </ContentContainer>
        </section>
    )
}

export default InfoBoxes
