import React from "react"
import InfoBoxes from "./InfoBoxes"

import bgImg from "../../assets/BackgroundImages/ZEN/Horizen_ZEN_main2.png"

const InfoBoxesSection = () => {
    return (
        <div
            className="pt-36"
            style={{
                backgroundImage: `url(${bgImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "50% -120px",
            }}
        >
            <InfoBoxes />
        </div>
    )
}

export default InfoBoxesSection
