import { Helmet } from "react-helmet"
import React from "react"

function ZenHelmet() {
    return (
        <Helmet>
            <title>ZEN - The Currency of Horizen</title>
            <meta property="og:title" content="ZEN - The Currency of Horizen" />
            <meta
                name="twitter:title"
                content="ZEN - The Currency of Horizen"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin that is available on all major exchanges, including Coinbase, Bittrex, Binance, and more."
            />
            <meta
                property="og:description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin that is available on all major exchanges, including Coinbase, Bittrex, Binance, and more."
            />
            <meta
                name="twitter:description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin that is available on all major exchanges, including Coinbase, Bittrex, Binance, and more."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/zen.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/zen.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="zen, zen coin, zen crypto, horizen zen, zencash, zen cryptocurrency, cryptocurrency, zen currency, zen money, zen cash"
            />
            <link rel="canonical" href="https://www.horizen.io/zen/" />
            <meta property="og:url" content="https://www.horizen.io/zen/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default ZenHelmet
