import React from "react"
import NewsletterForm from "../NewsletterForm"
import MerchantDirectory from "./MerchantDirectory"
import LatestUpdatesBackground from "../../assets/LatestUpdatesBackground.png"

const MerchantDirectoryWithNewsletterForm = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${LatestUpdatesBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "50% center",
            }}
        >
            <div className="pt-48">
                <MerchantDirectory />
            </div>
            <div className="pt-60">
                <NewsletterForm />
            </div>
        </div>
    )
}

export default MerchantDirectoryWithNewsletterForm
