import React from "react"

const ZenHeroImg = () => {
    return (
        <svg
            width="100"
            height="37"
            viewBox="0 0 100 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 37V29.4L15.62 8.32001H0V0H28.1V7.60001L12.43 28.68H28.49V37H0Z"
                fill="white"
            />
            <path
                d="M90.8702 37L75.3602 15.59V37H65.9102V0H75.6402L90.4902 20.36V0H100V37H90.8702Z"
                fill="white"
            />
            <path d="M60.3998 0H33.5098V8.38H60.3998V0Z" fill="white" />
            <path
                d="M60.3998 14.31H33.5098V22.69H60.3998V14.31Z"
                fill="url(#paint0_linear_2067_8)"
            />
            <path d="M60.3998 28.62H33.5098V37H60.3998V28.62Z" fill="white" />
            <defs>
                <linearGradient
                    id="paint0_linear_2067_8"
                    x1="33.5098"
                    y1="18.5"
                    x2="60.3998"
                    y2="18.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0E9DE5" />
                    <stop offset="1" stopColor="#26DB8D" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default ZenHeroImg
