import React from "react"

import Layout from "../templates/Layout"
import ZenHelmet from "../components/SEO/ZenHelmet"
import ZenHero from "../components/ZEN/ZenHero"
import MerchantDirectoryWithNewsletterForm from "../components/ZEN/MerchantDirectoryWithNewsletterForm"
import InfoBoxesSection from "../components/ZEN/InfoBoxesSection"
import MetricsOverview from "../components/ZEN/MetricsOverview"

const ZEN = () => {
    return (
        <Layout>
            <ZenHelmet />
            <div className="bg-horizen-hp-bg">
                <ZenHero />
                <MetricsOverview />
                <InfoBoxesSection />
                <MerchantDirectoryWithNewsletterForm />
            </div>
        </Layout>
    )
}

export default ZEN
