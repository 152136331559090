import React from "react"
import NormalPageHero from "../NormalPageHero"
import ZenHeroImg from "./ZenHeroImg"
import * as styles from "../../styles/zen/zen.module.css"

const ZenHero = () => {
    return (
        <section
            className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.zenHero}`}
        >
            <NormalPageHero
                img={<ZenHeroImg />}
                titleKey="zen.hero.title"
                subtitleKey="zen.hero.p1"
            />
        </section>
    )
}

export default ZenHero
