import React from "react"

export type CustomButton = {
    capitalLettersOff?: boolean
    label: string
    customClass?: string
    href?: string
    openNewTab?: boolean
}

const beforeAfterCssRules = `relative after:absolute after:w-2 after:h-full after:border-2 after:top-0 before:top-0
    after:right-0 after:border-l-0 before:border-links before:absolute before:w-2 before:h-full 
    before:border-2 before:left-0 before:border-r-0 after:border-links hover:before:border-horizen-green hover:after:border-horizen-green 
    disabled:after:border-horizen-content-grey disabled:before:border-horizen-content-grey`

const Button: React.FC<
    React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > &
        CustomButton
> = ({
    capitalLettersOff,
    type = "button",
    label,
    customClass,
    href,
    openNewTab,
    ...props
}) => {
    /* eslint-disable react/jsx-props-no-spreading */

    const button = (
        <button
            className={`${beforeAfterCssRules} px-4 md:px-6 text-white font-bold text-base md:text-xl !leading-10 disabled:text-horizen-grey disabled:cursor-not-allowed ${customClass} ${
                capitalLettersOff ? "" : "tracking-[0.3em]"
                // } hover:scale-y-[1.025] transition-all hover:tracking-[0.35em]`}
            }`}
            // eslint-disable-next-line react/button-has-type
            type={type}
            {...props}
        >
            {capitalLettersOff ? label : label.toUpperCase()}
        </button>
    )
    return href ? (
        <a
            href={href}
            target={openNewTab ? "_blank" : ""}
            rel="noopener noreferrer"
        >
            {button}
        </a>
    ) : (
        button
    )
}

export default Button
