import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import ContentContainer from "../../templates/ContentContainer"
import merchantsImg from "../../assets/ZEN/merchants.png"
import SectionHeading from "../SectionHeading"
import Button from "../Button"

const MerchantDirectory = () => {
    const { formatMessage } = useIntl()

    return (
        <section>
            <ContentContainer>
                <div className="text-white grid lg:grid-cols-[308px,1fr] gap-24">
                    <img
                        className="hidden lg:block"
                        src={merchantsImg}
                        alt="ZEN coin"
                    />
                    <div>
                        <SectionHeading>
                            {formatMessage({
                                id: "zen.merchantDirectory.sectionHeading",
                            })}
                        </SectionHeading>
                        <p className="mt-6 mb-8 text-lg text-horizen-content-grey leading-6">
                            {formatMessage({
                                id: "zen.merchantDirectory.content",
                            })}
                        </p>
                        <Button
                            label={formatMessage({
                                id: "zen.merchantDirectory.cta",
                            })}
                            href="/merchants"
                        />
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default MerchantDirectory
